import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import faqStyles from "../utilities/pageStyles/faq.module.scss"
import HELM from "../components/helmet.js"
import Layout from "../components/layout/layout.js"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export default function FAQ() {
  const faqQuery = useStaticQuery(graphql`
    query {
      faqPic: contentfulAsset(title: { eq: "FAQ Picture" }) {
        fluid(maxHeight: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      faqCategory: allContentfulFaq(sort: { fields: categoryOrder }) {
        edges {
          node {
            categoryOrder
            category
            questionOrder
            question
            answer {
              json
            }
          }
        }
      }
    }
  `)

  const categoryData = faqQuery.faqCategory.edges

  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)

  useEffect(() => {
    let initCategories = []

    const getAndSetCategories = () =>
      categoryData.forEach(({ node }) => {
        if (!initCategories.includes(node.category)) {
          initCategories = [...initCategories, node.category]
        }
      })

    getAndSetCategories()
    setCategories(initCategories)
    setSelectedCategory(initCategories[0])
  }, [categoryData])

  const renderCategories = () => {
    return categories.map((category, i) => {
      return (
        <button
          key={`faq-${category}-${i}`}
          className={faqStyles.category}
          onClick={() => setSelectedCategory(category)}
          style={{
            backgroundColor:
              selectedCategory === category ? "#70116b" : "transparent",
            color: selectedCategory === category ? "white" : "#70116b",
          }}
        >
          {category}
        </button>
      )
    })
  }

  const options = {
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  }

  const renderQuestions = () => {
    return categoryData
      .filter(({ node }) => node.category === selectedCategory)
      .sort((a, b) => a.node.questionOrder - b.node.questionOrder)
      .map(({ node }) => {
        return (
          <div className={faqStyles.faqBox}>
            <div className={faqStyles.faqQuestionBox}>
              <span className={faqStyles.q}>Q.</span>
              <h3 className={faqStyles.faqQuestion}>{node.question}</h3>
            </div>
            <div className={faqStyles.faqAnswerBox}>
              <span className={faqStyles.a}>A.</span>
              <div className={faqStyles.faqAnswer}>
                {documentToReactComponents(node.answer.json, options)}
              </div>
            </div>
          </div>
        )
      })
  }

  return (
    <Layout>
      <HELM title="FAQ" keywords={[`Who`, `What`, `Where`, `How`, `When`]} />
      <div className={faqStyles.container}>
        <div className={faqStyles.headingContainer}>
          <div className={faqStyles.headingImage}>
            <Img alt="Lilly Feather Reading" fluid={faqQuery.faqPic.fluid} />
          </div>
          <h1 className={faqStyles.headingText}>
            Everything you need to know All in one page
          </h1>
        </div>
        <div className={faqStyles.faqContainer}>
          <h1 className={faqStyles.faqHeading}>Frequently Asked Questions</h1>
        </div>
        <div className={faqStyles.categoryAndQuestions}>
          <div className={faqStyles.categories}>{renderCategories()}</div>
          <div className={faqStyles.questions}>
            <h2 className={faqStyles.selectedCategory}>{selectedCategory}</h2>
            {renderQuestions()}
          </div>
        </div>
      </div>
    </Layout>
  )
}
